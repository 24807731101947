.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  border: 1px solid black;
  padding: 8px;
  text-align: left;
}
@keyframes highlight {
  0% {
    background-color: rgb(194, 59, 59);
  }
  100% {
    background-color: rgb(23, 94, 202);
  }
}

.highlighted {
  animation: highlight 1s infinite alternate;
  animation-iteration-count: 60;
  transition: background-color 0.5s linear;
}

body{
  background-color: #090a26;
  padding: 0px;
}

.logo-dashboard{
  text-align: center;
}

.device-dashboard{
  color: white;
}

.logo {
  width: 450px;
}

.dashboard-tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.online-row {
  background-color: green;
}

tbody tr {
  cursor: pointer;
}

tbody tr:hover{
  opacity: 0.5;
}

.dashboard-tabs button[aria-selected='true'] {
  border-top: #f6eeab;
  border-top-width: 3px;
  border-top-style: solid;
  border-radius: 0px;
  background-color: #120d3b !important;
}

.dashboard-tabs button {
  padding: 0px;
  border-radius: 0px;
  background-color: #0b0825 !important;
}

.MuiTabs-indicator {
  width: 0px !important;
}

.MuiTouchRipple-root  {
  width: 0px !important;
}

.loading-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.add-btn-wrapper {
  display: flex;
  justify-content: end;
  padding: 18px;
}

.css-1212uk9-MuiTabs-indicator, .css-1nu2hs8 {
  border: 1px solid #0b0825 !important;
}